
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  loadingController,
  alertController,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonImg,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import Empty from "../../../../../components/Empty.vue";
import { Analytics } from "../../../../../common/analytics";
/**
 * services
 */
import services from "../../../../../services";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    Empty,
    IonSpinner,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonImg,
  },
  props: {
    // userId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup(props) {
    const router = useRouter();
    const screenName = `PAGE-USER_${props.id}_POST_LIKE`;
    const analytics = new Analytics();

    const state = reactive({
      post: {
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1,
      },
      contentLoading: true,
      isScrollDisabled: false,
    });

    const fetch = async (event: any = null, loadMore = false) => {
      const params = {
        size: state.post.size,
        page: state.post.page,
      };
      await services.postLikeFindone(props.id, params).then(
        (response) => {
          const { data } = response;
          if (event) {
            if (event.type == "ion-refresh") {
              state.post.items = [];
            }
          }

          data.items.some((item: object) => {
            state.post.items.push(item);
          });

          state.post.totalItems = data.totalItems;
          state.post.totalPages = data.totalPages;
          state.post.loading = false;
          state.post.page++;
          if (event) {
            if (data.items.length == 0 && loadMore) {
              state.isScrollDisabled = true;
            }
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          console.log(message);
          state.post.loading = false;
          if (event) {
            if (event.type == "ion-refresh") {
              state.post.items = [];
            }
          }
          state.isScrollDisabled = true;
        }
      );
      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    const presentAlert = async (message: any, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    //하트 추가
    const onLike = async (item: any) => {
      console.log(item.id);

      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "잠시 기다려주세요.",
      });
      await loading.present();

      const params = {
        postId: item.id,
      };
      if (item.like == 0) {
        services.likeAdd(params).then(
          (response) => {
            console.log(response);
            item.like = 1;
            item.likeCount = item.likeCount + 1;
            loading.dismiss();
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }

            setTimeout(function () {
              loading.dismiss();
              presentAlert(message);
            }, 1000);
          }
        );
      } else {
        services.likeRemove(params).then(
          (response) => {
            console.log(response);
            item.like = 0;
            item.likeCount = item.likeCount - 1;
            loading.dismiss();
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }

            setTimeout(function () {
              loading.dismiss();
              presentAlert(message);
            }, 1000);
          }
        );
      }
    };

    const onLink = (id: number) => {
      router.push({
        name: "PostId",
        params: { id: id },
      });
    };

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500);
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.post.page = 1;
      state.post.loading = true;
      state.isScrollDisabled = false;
      fetch(event);
    };

    // onMounted
    onMounted(() => {
      state.post.loading = true;
      state.post.items = [];

      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    const onClose = () => {
      window.history.length > 1 ? router.go(-1) : router.push("/");
    };

    const onUserLink = (id: number) => {
      /*
      router.push({
        name: "UserId",
        params: {id: id}
      })
      */
    };

    return {
      state,
      onRefresh,
      onLike,
      onLink,
      onUserLink,
      onClose,
      onLoadData,
    };
  },
});
