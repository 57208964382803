
import {
  IonPage,
  IonContent,
  modalController,
  loadingController,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  alertController,
  IonImg,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import UserIdPostLike from "../User/Id/Post/Like/Like.vue";
import UserIdMission from "../User/Id/Mission/Mission.vue";
import UserIdStamp from "../User/Id/Stamp/Stamp.vue";
import UserIdGift from "../User/Id/Gift/Gift.vue";
import Empty from "../../components/Empty.vue";
import { useStore } from "vuex";
import { Analytics } from "../../common/analytics";
/**
 * services
 */
import services from "../../services";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    Empty,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSpinner,
    IonRefresher,
    IonRefresherContent,
    IonImg,
  },
  props: {
    // userId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const screenName = "PAGE-USER";
    const screenNameid = `PAGE-USER_${props.id}`;
    const analytics = new Analytics();
    //data
    const state = reactive({
      user: {
        loading: true,
        data: {
          id: 0,
          follow: 0,
        },
        userId: 0,
      },
      post: {
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1,
      },
      myUser: {},
      contentLoading: true,
      isScrollDisabled: false,
      isTabUser: true,
    });

    //async (event: any = null, loadMore = false)
    const fetch = async (event: any = null, loadMore = false) => {
      if (!loadMore) {
        await services.authUser().then(
          (response) => {
            const { data } = response;
            state.myUser = data;

            state.user.data = data;
            state.user.loading = false;
            console.log(state.user.data);
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            console.log(message);
          }
        );

        if (props.id) {
          // 본인 or 3자
          await services.userfindOne(props.id).then(
            (response) => {
              const { data } = response;
              state.user.data = data;
              state.user.loading = false;

              console.log(state.user.data);
            },
            (error) => {
              let message = "";
              try {
                message = error.response.data.message;
              } catch (e) {
                message = "";
                console.log(e);
              }
              console.log(message);
              console.log(message);
              state.user.loading = false;
            }
          );
        }
      }

      const params = {
        page: state.post.page,
        size: state.post.size,
      };

      await services.postUserFindone(state.user.data.id, params).then(
        (response) => {
          const { data } = response;
          if (event) {
            if (event.type == "ion-refresh") {
              state.post.items = [];
            }
          }

          data.items.some((item: object) => {
            state.post.items.push(item);
          });

          state.post.totalItems = data.totalItems;
          state.post.totalPages = data.totalPages;
          state.post.loading = false;
          state.post.page++;
          if (event) {
            if (data.items.length == 0 && loadMore) {
              state.isScrollDisabled = true;
            }
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          console.log(message);
          state.post.loading = false;
          if (event) {
            if (event.type == "ion-refresh") {
              state.post.items = [];
            }
          }
          state.isScrollDisabled = true;
        }
      );

      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.post.page = 1;
      state.post.loading = true;
      state.isScrollDisabled = false;
      fetch(event);
    };

    // onMounted
    onMounted(() => {
      if (router.currentRoute.value.name == "UserId") {
        analytics.setScreenName(screenNameid);
        state.isTabUser = false;
      } else {
        state.isTabUser = true;
      }

      state.post.items = [];

      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    const onModalPostLike = async () => {
      const modal = await modalController.create({
        component: UserIdPostLike,
        componentProps: { id: state.user.data.id },
      });
      return modal.present();
    };

    const onModalMission = async () => {
      const modal = await modalController.create({
        component: UserIdMission,
        componentProps: { id: state.user.data.id },
      });
      return modal.present();
    };

    const onModalStamp = async () => {
      const modal = await modalController.create({
        component: UserIdStamp,
        componentProps: { id: state.user.data.id },
      });
      return modal.present();
    };

    const onModalGift = async () => {
      const modal = await modalController.create({
        component: UserIdGift,
        componentProps: { id: state.user.data.id },
      });
      return modal.present();
    };

    const presentAlert = async (message: any, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    //하트 추가
    //  const onLike = async (item: any)
    const onLike = async (item: any) => {
      console.log(item.id);

      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "잠시 기다려주세요.",
      });
      await loading.present();

      const params = {
        postId: item.id,
      };
      if (item.like == 0) {
        services.likeAdd(params).then(
          (response) => {
            console.log(response);
            item.like = 1;
            item.likeCount = item.likeCount + 1;
            loading.dismiss();
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }

            setTimeout(function () {
              loading.dismiss();
              presentAlert(message);
            }, 1000);
          }
        );
      } else {
        services.likeRemove(params).then(
          (response) => {
            console.log(response);
            item.like = 0;
            item.likeCount = item.likeCount - 1;
            loading.dismiss();
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }

            setTimeout(function () {
              loading.dismiss();
              presentAlert(message);
            }, 1000);
          }
        );
      }
    };

    const onPostId = (id: number) => {
      router.push({
        name: "PostId",
        params: { id: id },
      });
    };

    const onStampLink = (id: number, title: string) => {
      store.commit("page", {
        title: title,
      });
      router.push({
        name: "StampId",
        params: { id: id },
      });
    };

    const onFollowLink = () => {
      router.push({
        name: "UserIdFollow",
        params: { id: state.user.data.id },
      });
    };

    const onSettingLink = () => {
      router.push({
        name: "Setting",
      });
    };

    //const onLoadData = (event: any)
    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500);
    };

    return {
      state,
      onModalPostLike,
      onModalMission,
      onModalStamp,
      onModalGift,
      onStampLink,
      onPostId,
      onLike,
      onLoadData,
      onRefresh,
      onFollowLink,
      onSettingLink,
    };
  },
});
